@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.SrModalContent__modalContent {
  position: relative;
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  background: #1f1f22;
  border-radius: 8px;
  color: #e8e8e8;
}

.SrModalContent__childrenContent {
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: rem(6px);
    max-height: rem(70px);
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: rem(6px);
    border-radius: 4px;
  }
}

.SrModalContent__headerModal {
  width: 100%;
  height: rem(48px);
  padding: rem(14px) rem(20px) rem(12px);
  background: #2d2d2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: rem(1px) solid #6c6c6c;
  border-radius: 8px 8px 0 0;
}

.SrModalContent__headerTitle {
  font-size: rem(17px);
  font-weight: 600;
  line-height: rem(24px);
  font-family: $main-font;
  text-align: center;
  height: rem(24px);
}

.SrModalContent__closeModalBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  stroke: #595959;
  transition: $default-transition;

  &:hover {
    transform: scale(1.2);
    opacity: 0.7;
  }

  &:active {
    stroke: #fff;
    opacity: 1;
  }
}

.SrModalContent__actionButtonsWrapper {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 15px;
  padding: rem(20px);
}

.SrModalContent__actionButtonCancel {
  background-color: #373d43;
  color: #ffffff;
}
