@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.ViewerHeaderBts__wrapper {
  display: flex;
}

.ViewerHeaderBts__tooltipForCopyURL {
  cursor: pointer;
}

.ViewerHeaderBts__btnWrapper {
  width: rem(65px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #3A3A3A;
  
  @media (max-width: 1000px) {
    display: none;
  }
}

.ViewerHeaderBts__btnIconWrapper {
  width: rem(34px);
  height: rem(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: rem(15px);
  border-radius: 4px;
  background: #373D43;
  cursor: pointer;
  border: none;
  color: white;
  gap: 5px;
}

.ViewerHeaderBts__btnUnassignBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(130px);
  border-left: 1px solid #3A3A3A;
  border-right: 1px solid #3A3A3A
}

.ViewerHeaderBts__btnUnassignWrapper {
  position: relative;
  height: rem(32px);
  border-radius: 4px;
}

.ViewerHeaderBts__unassignBtn {
  height: rem(32px);
  padding: rem(6px) rem(12px);
  font-weight: 400;
  font-size: rem(15px);
  white-space: nowrap;
}

.ViewerHeaderBts__unassignBtn {
  background: #373D43;
  color: white;
}
