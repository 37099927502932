@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

@import './_normalize';
// Roboto fonts
@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';

// Myriad Pro fonts
@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro'), url('../../shared/fonts/MYRIADPRO-REGULAR.otf') format('opentype');
}

@font-face {
  font-family: 'Myriad Pro';
  font-weight: 600;
  src: local('Myriad Pro'), url('../../shared/fonts/MYRIADPRO-SEMIBOLD.otf') format('opentype');
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  font-family: $main-font;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // remove highlight color
}

/* styles for all scrolls in the app (Chrome/Edge/Safari) */
*::-webkit-scrollbar {
  height: rem(12px);
  width: rem(12px);
}

*::-webkit-scrollbar-track {
  background: #1f1f22;
  width: rem(5px);
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  background-color: #6c6c6c;
  width: rem(12px);
  border-radius: 2px;
}

*::-webkit-scrollbar-corner {
  opacity: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.rti--container {
  background-color: #1f1f22;
  width: 100%;

  & span.rti--tag {
    padding: 6px 8px;
    background-color: #6c6c6c;
    border-radius: 6px;
    margin: 0 4px 4px 0;
    font-size: rem(14px);
    color: #d8d8d8;
    display: flex;
    align-items: center;

    & span {
      margin-right: rem(8px);
    }

    & button {
      color: #595959;
    }
  }
}

.rti--tag {
  padding: 6px 8px;
  background-color: #6c6c6c;
  border-radius: 6px;
}

input.rti--input {
  background-color: transparent;
  color: #d8d8d8;
  width: 100%;
  font-size: rem(15px);
}

/* styles for react-datepicker */
.react-datepicker {
  background: #4b4b4c;
  padding: rem(20px);
  border-radius: 8px;
  border-color: #4b4b4c;

  @media (max-width: rem(850px)) {
    padding: rem(10px);
  }
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__header {
  background: #4b4b4c;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: #4b4b4c;
}

.react-datepicker__month-container {
  float: left;

  @media (max-width: rem(850px)) {
    float: none;
  }
}

.react-datepicker__current-month {
  margin-bottom: rem(25px);
  font-family: $main-font;
  font-weight: 400;
  font-size: rem(17px);
  color: #f2f2f2;
}

.react-datepicker__day-name {
  width: rem(40px);
  height: rem(40px);
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $main-font;
  font-weight: 400;
  font-size: rem(13px);
  color: #ffffff;
}

.react-datepicker__day {
  width: rem(40px);
  height: rem(40px);
  margin: rem(6px) 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: $main-font;
  font-weight: 400;
  font-size: rem(13px);
  color: #d8d8d8;

  &:hover {
    background: #4b4b4c;
    color: #d8d8d8;
    border: 1px solid #b4b4b4;
    border-radius: 50%;
  }
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__navigation {
  margin: rem(27px) 0 0 rem(29px);
}

.react-datepicker__navigation--next {
  margin: rem(27px) rem(29px) 0 0;
}

.react-datepicker__day--today {
  background: #4b4b4c;
}

.react-datepicker__day--selected {
  background: #4b4b4c;
  border: 1px solid #b4b4b4;
  border-radius: 50%;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background: #ffd200 !important;

  &:hover {
    color: #1a1a1a;
  }
}

.react-datepicker__day--selecting-range-start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-left: dotted;

  &:hover {
    border: 1px solid #d8d8d8 !important;
    border-radius: 50%;
  }
}

.react-datepicker__day--selecting-range-end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-right: dotted;

  &:hover {
    border: 1px solid #d8d8d8 !important;
    border-radius: 50%;
  }
}

.react-datepicker__day--in-range {
  background: #fff299;
  border-radius: 0;
  border: none;
  color: #1a1a1a;

  &:first-child {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  &:last-child {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.react-datepicker__day--in-selecting-range {
  border-radius: 0;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: #4b4b4c;
  border-color: #b4b4b4;
  border-top: dotted;
  border-bottom: dotted;

  &:first-child {
    border-left: dotted;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  &:last-child {
    border-right: dotted;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background: #4b4b4c !important;
  color: #d8d8d8 !important;
}

.react-datepicker__day--outside-month {
  border: none !important;
  display: none;
}

.react-datepicker__month > .react-datepicker__week:first-child {
  justify-content: flex-end;
}

.react-datepicker__month > .react-datepicker__week:last-child {
  justify-content: flex-start;
}

.react-datepicker__input-container > input:focus {
  border: 1px solid blue;
}

// google map controls
.gmnoprint {
  right: rem(50px) !important;
  bottom: rem(150px) !important;
}

// for static height for zula-app
:root {
  --zoom-scale: 1;
}

// styles for react-select with prefix custom-select-vr
div.custom-select-vr {
  &__indicators {
    display: none;
  }

  &__menu {
    background: gray;
  }

  &__menu-list {
    padding: 0;
  }

  &__control {
    padding: 5px;
    border: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      border: 0;
    }
  }

  &__option {
    cursor: pointer;
    background: #393939;
    padding: 10px;
    color: white;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      background-color: inherit;
    }

    &--is-selected {
      background: #282828;
    }
  }
}
