@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.ProfileContent__container {
  display: flex;
  gap: rem(10px);
  align-items: center;
  justify-content: center;
  z-index: 1111;
}

.ProfileContent__userInfo {
  display: flex;
  align-items: center;
}

.ProfileContent__userName {
  display: flex;
  padding-right: rem(10px);
  text-align: center;
}

.ProfileContent__userGreating {
  display: flex;
  align-items: center;
  border-right: 2px solid #c4c4c4;
  padding-right: rem(10px);
  margin-right: rem(12px);
  font-family: $main-font;
  font-size: rem(14px);
  color: #f2f2f2;
  cursor: default;

  @media (max-width: 900px) {
    display: none;
  }
}

.ProfileContent__onlyForCustomerRole {
  padding: rem(10px);
  font-family: $main-font;
  font-weight: 400;
  font-size: rem(15px);
  color: #f2f2f2;
}

.ProfileContent__userAvatarImg {
  width: rem(32px);
  height: rem(32px);
  border-radius: 50%;
  cursor: pointer;
}

.ProfileContent__userAvatarWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ProfileContent__dropDownMenu {
  width: rem(100px);
  position: absolute;
  top: rem(38px);
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #4b4b4c;
  border-radius: 4px;
  font-family: $main-font;
  font-weight: 400;
  font-size: rem(15px);
  color: #f2f2f2;

  &::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    right: rem(11px);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #4b4b4c transparent;
  }
}

.ProfileContent__buttons {
  width: 100%;
  height: rem(40px);
  background-color: #4b4b4c;
  border: none;
  border-radius: 4px;
  padding-left: rem(10px);
  text-align: left;

  &:hover {
    background-color: #595959;
  }
}

.ProfileContent__languagesWrapper {
  position: relative;
  padding: 0 !important;
}

.ProfileContent__languages {
  padding-bottom: 0 !important;
  z-index: 1;
}

.ProfileContent__languages button {
  border: #25292d;
  font-family: $main-font;
  font-size: rem(14px) !important;
  color: #f2f2f2;

  &:after {
    border: 0;
  }

  &[aria-expanded="true"]:after,
  :after {
    border: 0;
  }

  > span > span {
    &:last-child {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }
}

.ProfileContent__languages ul {
  background-color: #4b4b4c;
  border: #4b4b4c;
  font-family: $main-font;
  font-size: rem(14px) !important;
  color: #f2f2f2;
}

.ProfileContent__languages li {
  span {
    @media (max-width: 450px) {
      margin: auto;
    }

    span {
      &:last-child {
        @media (max-width: 450px) {
          display: none;
        }
      }
    }
  }
}

.ProfileContent__languages li:hover {
  color: black;
}

.ProfileContent__languagesListDropdownIcon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  z-index: 0;
}

.ProfileContent__userRolesSelect {
  outline: none;

  [class$="-control"] {
    background-color: transparent !important;
    color: #f2f2f2 !important;
    border: none;
    height: rem(40px) !important;
    border-radius: 4px;
    background-color: #2d2d2f;
    box-shadow: none !important;
    cursor: pointer;

    [class$="-ValueContainer"] {
      padding-right: 0;
    }

    [class$="-singleValue"] {
      color: #f2f2f2 !important;
      font-family: $main-font;
      font-size: rem(14px);
      line-height: rem(20px);

      @media (max-width: 450px) {
        font-size: rem(12px) !important;
      }
    }

    [class$="-Input"] {
      caret-color: transparent;
    }

    [class$="-IndicatorsContainer"] {
      color: #f2f2f2;

      [class$="-indicatorContainer"] {
        padding: none;
        color: #f2f2f2;
      }
    }

    [class$="-indicatorSeparator"] {
      display: none;
    }

    [class$="-placeholder"] {
      color: #d8d8d8;
      font-family: $main-font;
      font-size: rem(14px);
      line-height: rem(20px);
    }
  }

  [class$="-menu"] {
    background-color: #4b4b4c;
    width: rem(125px);
    color: #d8d8d8;
    font-family: $main-font;
    font-size: rem(14px);
    line-height: rem(20px);
    margin: 0;
    padding: 0;
    z-index: 1;
    text-align: left;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%; /* At the top of the tooltip */
      left: 20%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #4b4b4c transparent;
    }

    [class$="-option"] {
      cursor: pointer;
      background-color: #4b4b4c;
      height: rem(40px);

      &:hover,
      &:focus {
        background-color: #5b5b5b;
      }

      &[aria-disabled="true"] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.ProfileContent__withoutUserAvatar {
  width: rem(32px);
  height: rem(32px);
  background-color: #f13c3c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
}

.ProfileContent__modalContentAdditionalStyles {
  max-width: rem(456px);

  @media (max-width: $breakpoint-sm) {
    width: rem(250px);
  }
}
