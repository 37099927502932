@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.Header__container {
  position: relative;
  height: rem($headerHeight);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(5px) rem(33px);
  background: #2d2d2f;
}

.Header__viewerBackBtnWrapper {
  min-width: rem(10px) !important;
  position: absolute !important;
  top: 50% !important;
  left: 16px;
  transform: translate(-50%, -50%) !important;

  &:hover {
    opacity: 0.7;
  }

  &Disabled {
    pointer-events: all !important;
    cursor: not-allowed !important;
  }
}

.Header__viewerPart {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.Header__tooltipForVisionImagesBtn {
  cursor: pointer;
}

.Header__btnBase {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3a3a3a;
}

.Header__btnSwitchImgWrapper {
  @extend .Header__btnBase;
  width: rem(65px);
}

.Header__btnDisableStaticBoxWrapper {
  @extend .Header__btnBase;
  padding-right: rem(15px);
}

.Header__btnIconWrapper {
  width: rem(34px);
  height: rem(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: rem(15px);
  border-radius: 4px;
  background: #373d43;
  cursor: pointer;
  border: none;
  color: white;
  gap: 5px;
}

.Header__btnHideBoxButton {
  display: block;
  height: rem(32px);
  padding: rem(6px) rem(12px);
  font-weight: 400;
  font-size: rem(15px);
  white-space: nowrap;
  background: #373D43;
  color: white;
  width: rem(120px);
}

.Header__viewerRecording {
  font-weight: 600;
  font-size: rem(15px);
  color: #d8d8d8;
  margin: 0 rem(10px);
}

.Header__viewerSaveTime {
  margin-left: rem(10px);
  font-weight: 400;
  font-size: rem(15px);
  color: #949494;
  text-decoration: underline;

  @media (max-width: 700px) {
    display: none;
  }
}

.Header__logoWrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
  max-width: rem(193px);
}

.Header__leftBlockWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 25px;

  @media (max-width: 800px) {
    gap: 5px;
  }
}

.Header__userWrapper {
  margin: rem(3px);
  display: flex;
  gap: rem(10px);
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.Header__authRequested {
  color: white;
}

button.Header__burger {
  @media screen and (min-width: 750px) {
    display: none;
  }
}

.Header__logo {
  min-width: 48px;
  max-width: 48px;
  width: 48px;
}

.Header__positionInRunBlockWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.Header__positionInRunLabelText {
  width: 100%;
  padding-bottom: rem(6px);
  font-weight: 600;
  font-size: rem(14px);
  color: #d8d8d8;
}
