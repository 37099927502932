@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.EditUserProfile__content {
  width: 100%;
  display: flex;
  padding: rem(20px);
  background-color: #1f1f22;
  color: #e8e8e8;
  flex: 0 0 auto;
}

.EditUserProfile__avatarUploadContainer {
  width: rem(96px);
  height: rem(96px);
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  font-family: $main-font;
  font-size: rem(48px);
  color: #1f1f22;
  overflow: hidden;

  @media (max-width: $breakpoint-sm) {
    width: rem(60px);
    height: rem(60px);
    font-size: rem(20px);
  }
}

.EditUserProfile__noAvatar {
  @extend .EditUserProfile__avatarUploadContainer;
  background-color: #f13c3c;
  padding: rem(19px);

  @media (max-width: $breakpoint-sm) {
    padding: rem(16px);
  }
}

.EditUserProfile__avatarImg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.EditUserProfile__userNameAndUploadButtonBlock {
  display: flex;
  flex-direction: column;
  padding-left: rem(20px);
  justify-content: space-around;
}

.EditUserProfile__userName {
  font-family: $main-font;
  font-size: rem(15px);
  color: #ffffff;
}

.EditUserProfile__footerButton {
  background: #2d2d2f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: rem(1px) solid #6c6c6c;
  padding: rem(20px);
}

.EditUserProfile__uploadInputHidden {
  display: none;
}

.EditUserProfile__uploadButton {
  background-color: #1f1f22;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: rem(5px) rem(10px);
  font-family: $main-font;
  font-size: rem(15px);
  color: #d8d8d8;

  @media (max-width: $breakpoint-sm) {
    font-size: rem(10px);
  }
}

.EditUserProfile__inputBlock {
  display: flex;
}

.EditUserProfile__deleteFileButton {
  cursor: pointer;
  padding-top: rem(5px);
  padding-left: rem(25px);
  text-decoration: underline;
  text-align: center;
  font-family: $main-font;
  font-size: rem(15px);
  color: #d8d8d8;
  background-color: transparent;
}
