@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.App__errorBoundaries {
  padding: rem(20px);
  font-size: rem(17px);
}

.App__suspenseLoader {
  position: absolute;
  width: 100%;
  height: $realFullHeight;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin: auto;
  background-color: #1B1E21;
  z-index: 10;
}
