@use "sass:math";

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

$base-font-size: 16px;

// mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

// font
$main-font: 'Roboto', sans-serif;

// default transition for css style transition
$default-transition: all 0.1s ease-in;

// header height
$headerHeight: 56px;

// movable sidebar width
$movableSidebarWrapperWidth: 95px;
$recognitionsWrapperWidth: 300px;

// track locations params
$libraryTrackLocationsTabelHeaderHeight: 32px;

// equals to 100vh, we need it becauze of zooming
$realFullHeight: calc(100vh / var(--zoom-scale));
$realFullWidth: calc(100vw / var(--zoom-scale));

.ConfirmUnassignActionModal__wrapper {
  display: flex;
  align-items: center;
  padding: rem(20px);
  padding-bottom: 0;
}

.ConfirmUnassignActionModal__questionIcon {
  height: rem(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(5px);
  font-size: rem(15px);
  border-radius: 4px;
  background: #373d43;
  color: white;
  gap: 5px;
}

.ConfirmUnassignActionModal__questionText {
  min-height: rem(40px);
  display: flex;
  justify-content: center;
  padding: rem(12px);
  font-weight: 400;
  font-size: rem(17px);
  color: #B4B4B4;
}
